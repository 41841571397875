import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import { PageLoaderFallback } from '../../layout/PageLoaderFallback';
import { ApolloWrapper } from '../../wrapper/Apollowrapper';

const Index = loadable(
  () => import(/* webpackPrefetch: true */ './index/Index'),
  {
    fallback: <PageLoaderFallback />
  }
);
const PricelistSale = loadable(
  () => import(/* webpackPrefetch: true */ './about/PricelistSale'),
  {
    fallback: <PageLoaderFallback />
  }
);
const PricelistBuy = loadable(
  () => import(/* webpackPrefetch: true */ './about/PricelistBuy'),
  {
    fallback: <PageLoaderFallback />
  }
);
const KunderVelgerOssKampanje = loadable(
  () =>
    import(/* webpackPrefetch: true */ '../kampanje/trondheim/KunderVelgerOss'),
  {
    fallback: <PageLoaderFallback />
  }
);
const OfficeBrokers = loadable(
  () => import(/* webpackPrefetch: true */ './brokers/Index'),
  {
    fallback: <PageLoaderFallback />
  }
);
const Description = loadable(
  () => import(/* webpackPrefetch: true */ './about/Description'),
  {
    fallback: <PageLoaderFallback />
  }
);
const DeliveryDescription = loadable(
  () => import(/* webpackPrefetch: true */ './about/DeliveryDescription'),
  {
    fallback: <PageLoaderFallback />
  }
);
const About = loadable(
  () => import(/* webpackPrefetch: true */ './about/Index'),
  {
    fallback: <PageLoaderFallback />
  }
);
const TransparencyAct = loadable(
  () => import(/* webpackPrefetch: true */ './transparencyact/TransparencyAct'),
  {
    fallback: <PageLoaderFallback />
  }
);
const News = loadable(
  () => import(/* webpackPrefetch: true */ './news/Index'),
  {
    fallback: <PageLoaderFallback />
  }
);
const SingleNews = loadable(
  () => import(/* webpackPrefetch: true */ './news/Single'),
  {
    fallback: <PageLoaderFallback />
  }
);
const Contact = loadable(
  () => import(/* webpackPrefetch: true */ './contact/Index'),
  {
    fallback: <PageLoaderFallback />
  }
);
const FinancialAdvisers = loadable(
  () => import(/* webpackPrefetch: true */ './financial/Index'),
  {
    fallback: <PageLoaderFallback />
  }
);
const References = loadable(
  () => import(/* webpackPrefetch: true */ './references/Index'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Feedback = loadable(
  () => import(/* webpackPrefetch: true */ './references/Feedback'),
  {
    fallback: <PageLoaderFallback />
  }
);
const NoMatch = loadable(
  () => import(/* webpackPrefetch: true */ '../404Old'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Office: React.FC = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path="/:urltag(\w+)/prisliste"
          render={(props: any) => {
            return (
              <ApolloWrapper>
                <PricelistSale {...props} />
              </ApolloWrapper>
            );
          }}
        />
        <Route
          exact
          path="/:urltag(\w+)/prislistekjop"
          render={(props: any) => {
            return (
              <ApolloWrapper>
                <PricelistBuy {...props} />
              </ApolloWrapper>
            );
          }}
        />
        <Route
          exact
          path="/trondheim/kampanje"
          component={KunderVelgerOssKampanje}
        />
        <Route
          exact
          path="/:urltag(\w+)/meglere"
          render={(props: any) => {
            return (
              <ApolloWrapper>
                <OfficeBrokers {...props} />
              </ApolloWrapper>
            );
          }}
        />
        <Route
          exact
          path="/:urltag(\w+)/om-oss/beskrivelse"
          component={Description}
        />
        <Route
          exact
          path="/:urltag(\w+)/om-oss/leveransebeskrivelse"
          component={DeliveryDescription}
        />
        <Route exact path="/:urltag(\w+)/om-oss" component={About} />
        <Route
          exact
          path="/:urltag(\w+)/aapenhetsloven"
          component={TransparencyAct}
        />
        <Route exact path="/:urltag(\w+)/nyheter" component={News} />

        <Route exact path="/:urltag(\w+)/kontakt" component={Contact} />
        <Route
          exact
          path="/:urltag(\w+)/finansraadgivere"
          component={FinancialAdvisers}
        />
        <Route
          exact
          path="/:urltag(\w+)/nyheter/:slug"
          component={SingleNews}
        />
        <Route exact path="/:urltag(\w+)/referanser" component={References} />
        <Route
          exact
          path="/:urltag(\w+)/referanser/skjema"
          component={Feedback}
        />
        <Route
          exact
          path="/:urltag(\w+)"
          render={(props: any) => {
            return (
              <ApolloWrapper>
                <Index {...props} />
              </ApolloWrapper>
            );
          }}
        />
        <Route component={NoMatch} />
      </Switch>
    </>
  );
};

export default Office;
